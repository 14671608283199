// ------------------------------------
// Imports
// ------------------------------------
import {
  getRentalRequestConfig,
  setPreloadPDFConfig,
  setRentalRequestConfig
} from "../assets/common/common";
import { addError } from './errors';
import apiClient from "../assets/common/interceptor";

// ------------------------------------
// Constants
// ------------------------------------
export const SET_ORGANISATION = 'SET_ORGANISATION';
export const SET_SERVICE = 'SET_SERVICE';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_PRODUCT_ID = 'SET_PRODUCT_ID';
export const SET_PRODUCT_NAME = 'SET_PRODUCT_NAME';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_DELIVERY_INSTRUCTIONS = 'SET_DELIVERY_INSTRUCTIONS';
export const SET_BULK_PRODUCT_CONFIG = 'SET_BULK_PRODUCT_CONFIG';
export const SET_PRODUCT_CONFIG = 'SET_PRODUCT_CONFIG';
export const CLEAR_ENTIRE_STATE = 'CLEAR_ENTIRE_STATE';
export const CLEAR_REDUX_CONFIG = 'CLEAR_REDUX_CONFIG';
export const RESET_CLIENT_DETAILS = 'RESET_CLIENT_DETAILS';
export const RESET_DELIVERY_INSTRUCTIONS = 'RESET_DELIVERY_INSTRUCTIONS';
export const RESET_LOCATION = 'RESET_LOCATION';
export const REQUEST_PDF_MAIL = 'REQUEST_PDF_MAIL';
export const PRELOAD_PDF = 'PRELOAD_PDF';

// ------------------------------------
// Actions
// ------------------------------------
export const setOrganisation = (payload) => ({
  type: SET_ORGANISATION,
  payload
});

export const setService = (payload) => ({
  type: SET_SERVICE,
  payload
});

export const setLocations = (payload) => ({
  type: SET_LOCATIONS,
  payload
});

export const setLocation = (payload) => ({
  type: SET_LOCATION,
  payload
});

export const setProductId = (payload) => ({
  type: SET_PRODUCT_ID,
  payload
});

export const setProductName = (payload) => ({
  type: SET_PRODUCT_NAME,
  payload
});

export const setClientDetails = (payload) => ({
  type: SET_CLIENT,
  payload
});

export const setDeliveryInstructions = (payload) => ({
  type: SET_DELIVERY_INSTRUCTIONS,
  payload
});

export function setBulkProductConfig (obj) {
  return ({
    type: SET_BULK_PRODUCT_CONFIG,
    payload: obj
  });
}

export function setProductConfig (key, value) {
  return ({
    type: SET_PRODUCT_CONFIG,
    key,
    value
  });
}

export function clearEntireState () {
  return ({
    type: CLEAR_ENTIRE_STATE
  });
}

export function clearReduxConfig () {
  return ({
    type: CLEAR_REDUX_CONFIG
  });
}

export function resetClientDetails () {
  return ({
    type: RESET_CLIENT_DETAILS
  });
}

export function resetDeliveryInstructions () {
  return ({
    type: RESET_DELIVERY_INSTRUCTIONS
  });
}

export function resetLocation () {
  return ({
    type: RESET_LOCATION
  });
}

export function preloadPDF (payload) {
  return ({
    type: PRELOAD_PDF,
    payload
  });
}

// ------------------------------------
// API calls
// ------------------------------------
export const placeOrder = (signal) => {
  return (dispatch) => {
    return apiClient.post('/organisation/order', getRentalRequestConfig())
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem...
          return Promise.reject(data.data);
        }
      })
      .catch(err => {
        if (!signal?.aborted) {
          localStorage.setItem('errorMessage', err.message);
          window.location.href = "/";
          if (typeof err === 'object' && typeof err.then === 'function') {
            err.then(e => {
              dispatch(addError(e.message));
            });
          }
        }
      });
  };
};

export const requestPDFMail = (payload) => {
  return (dispatch) => {
    return apiClient.post('/organisation/order/pdf/send', payload)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem...
          return Promise.reject(data.data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        if (typeof err === 'object' && typeof err.then === 'function') {
          err.then(e => {
            dispatch(addError(e.message));
          });
        }
      });
  };
};
// ------------------------------------
// Reducer
// ------------------------------------

const rentalRequestConfig = getRentalRequestConfig();
const initialState = Object.keys(rentalRequestConfig).length > 0
  ? rentalRequestConfig
  : {
    organisation: '',
    orgId: '',
    service: '',
    locations: {},
    location: {},
    productId: null,
    client: {
      initials: "",
      lastNamePrefix: "",
      lastName: "",
      sex: "",
      birthdate: "",
      hipWidth: null,
      upperlegLength: null,
      lowerlegLength: null,
      shoulderHeight: null,
      body_weight: ""
    },
    deliveryInstructions: {
      location: "",
      department_room_nr: "",
      delivery_instructions: "Bij client",
      instruction: "",
      remark: "",
      other_delivery_instructions: ""
    },
    productConfig: {},
    preloadedPDF: {}
  };

export const rentalRequestReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_SERVICE:
    setRentalRequestConfig({ ...state, service: action.payload });
    return { ...state, service: action.payload };
  case SET_ORGANISATION:
    setRentalRequestConfig({ ...state,
      organisation: action.payload.organisation.replace("&amp;", "&"), orgId: action.payload.orgId });
    return { ...state, organisation: action.payload.organisation.replace("&amp;", "&"), orgId: action.payload.orgId };
  case SET_LOCATIONS:
    setRentalRequestConfig({ ...state, locations: action.payload });
    return { ...state, locations: action.payload };
  case SET_LOCATION:
    setRentalRequestConfig({
      ...state,
      location: action.payload,
      deliveryInstructions: {
        ...state.deliveryInstructions,
        location: action.payload.location_name
      }
    });
    return {
      ...state,
      location: action.payload,
      deliveryInstructions: {
        ...state.deliveryInstructions,
        location: action.payload.location_name
      }
    };
  case SET_PRODUCT_ID:
    setRentalRequestConfig({ ...state, productId: action.payload });
    return { ...state, productId: action.payload };
  case SET_PRODUCT_NAME:
    setRentalRequestConfig({ ...state, productName: action.payload });
    return { ...state, productName: action.payload };
  case SET_CLIENT:
    setRentalRequestConfig({ ...state, client: { ...state.client, ...action.payload } });
    return { ...state, client: { ...state.client, ...action.payload } };
  case SET_DELIVERY_INSTRUCTIONS:
    setRentalRequestConfig({
      ...state,
      deliveryInstructions: {
        ...state.deliveryInstructions,
        ...action.payload
      }
    });
    return {
      ...state,
      deliveryInstructions: {
        ...state.deliveryInstructions,
        ...action.payload
      }
    };
  case SET_PRODUCT_CONFIG:
    setRentalRequestConfig({ ...state, productConfig: { ...state.productConfig, [action.key]: action.value } });
    return { ...state, productConfig: { ...state.productConfig, [action.key]: action.value } };
  case SET_BULK_PRODUCT_CONFIG:
    setRentalRequestConfig({ ...state, productConfig: action.payload });
    return { ...state, productConfig: action.payload };
  case CLEAR_ENTIRE_STATE:
    return state = {
      organisation: '',
      orgId: '',
      service: '',
      locations: {},
      location: {},
      productId: null,
      productName: '',
      client: {
        initials: "",
        lastNamePrefix: "",
        lastName: "",
        sex: "",
        birthdate: "",
        hipWidth: null,
        upperlegLength: null,
        lowerlegLength: null,
        shoulderHeight: null,
        body_weight: ""
      },
      deliveryInstructions: {
        location: "",
        department_room_nr: "",
        delivery_instructions: "Bij client",
        other_delivery_instructions: ""
      },
      productConfig: {},
    };
  case RESET_CLIENT_DETAILS:
    return { ...state, client: {
      initials: "",
      lastNamePrefix: "",
      lastName: "",
      sex: "",
      birthdate: "",
      hipWidth: null,
      upperlegLength: null,
      lowerlegLength: null,
      shoulderHeight: null,
      body_weight: ""
    }, };
  case RESET_DELIVERY_INSTRUCTIONS:
    return { ...state,  deliveryInstructions: {
      location: state.deliveryInstructions.location,
      department_room_nr: "",
      delivery_instructions: "Bij client",
      other_delivery_instructions: ""
    }, };
  case RESET_LOCATION: 
    return { ...state, organisation: "", service: "", locations: {} };
  case CLEAR_REDUX_CONFIG:
    return { ...state, productConfig: {} };
  case PRELOAD_PDF:
    setPreloadPDFConfig({ ...state, preloadedPDF: action.payload });
    return { ...state, preloadedPDF: action.payload };
  default:
    return state;
  }
};

export default rentalRequestReducer;
