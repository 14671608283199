// ------------------------------------
// Imports
// ------------------------------------
import { getMe, getSelectedOrder, setSelectedOrderConfig } from "../assets/common/common";
import { addError } from "./errors";
import moment from "moment";
import apiClient from "../assets/common/interceptor";

// ------------------------------------
// Constants
// ------------------------------------

export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const SEND_SEARCH_REQUEST = 'SEND_SEARCH_REQUEST';
export const RESET_SEARCH_REQUEST = 'RESET_SEARCH_REQUEST';
// ------------------------------------
// Actions
// ------------------------------------
export const setSelectedOrderAction = (payload) => ({
  type: SET_SELECTED_ORDER,
  payload
});

export const sendSearchRequest = (payload) => ({
  type: SEND_SEARCH_REQUEST,
  payload
});

export const resetSearchRequest = () => ({
  type: RESET_SEARCH_REQUEST
});
// ------------------------------------
// API calls
// ------------------------------------
export const setSelectedOrder = (data) => {
  return (dispatch) => {
    dispatch(setSelectedOrderAction(data));
  };
};

export const searchItems = (data, signal) => {
  return (dispatch) => {
    let queryParams = "";
    let orgOrEstablishment = data?.organisationId ? `?organisationId=${data?.organisationId}`
      : `?establishmentId=${data?.establishmentId}`;

    if (data?.provisionNumber){
      queryParams = orgOrEstablishment + `&externalId=${data?.provisionNumber}`;
    } else {
      const explodedDate = data?.clientBirthdate.split('/');
      const birthDate = explodedDate?.[2] + '-' + explodedDate?.[1] + '-' + explodedDate?.[0];
      queryParams = orgOrEstablishment + `&clientName=${data?.clientName}&clientBirthdate=${birthDate}`;
    }

    return apiClient.get(`/order/item/search${queryParams}`, {
      signal: signal
    })
      .then(response => {
        return response;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          dispatch(sendSearchRequest(data?.data));
          return Promise.resolve(data);
        } else {
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        return err.message;
      });
  };
};

export const serviceRequest = (data, type, signal) => {
  return (dispatch) => {
    const selectedOrder = getSelectedOrder();
    const me = getMe();

    // const
    const body = {
      orderUID: selectedOrder?.id,
      creationDate: moment().toISOString(),
      serviceRequestType: type,
      reason: data.reason, // "Wat moet er aangepast worden?" text
      caregiver: {
        fullName: me.name,
        emailAddress: me.preferred_username,
        phoneNumber: me.phonenumber_for_questions || "-",
        organisation: `${selectedOrder.caregiver.organisationUID}`
      },
      products: {
        quantity: 1,
        productUid: selectedOrder.product.uid,
        provisionNumber: selectedOrder.provisionNumber,
        ...selectedOrder.product
      },
      delivery: {
        deliveryLocation: data.department_room_nr,
        deliveryInstruction: data.delivery_instructions === "Anders" ?
          "Anders: " + data.remark
          : data.delivery_instructions
      },
      attachments: data.attachment,
      remark: data.remark || "-"
    };

    return apiClient.post(`/organisation/order/service-request`, body)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem
          return Promise.reject(data.data);
        }
      }).catch(err => {
        if (!signal?.aborted) {
          localStorage.setItem('errorMessage', err.message);
          window.location.href = "/";
          if (typeof err === "object" && typeof err.then === "function") {
            err.then(e => {
              dispatch(addError(e.message));
            });
          }
        }
      });
  };
};

export const bulkServiceRequest = (data, signal) => {
  return (dispatch) => {
    return apiClient.post(`/organisation/order/bulk-service-request`, data)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem
          return Promise.reject(data.data);
        }
      }).catch(err => {
        if (!signal?.aborted) {
          localStorage.setItem('errorMessage', err.message);
          window.location.href = "/";
          if (typeof err === "object" && typeof err.then === "function") {
            err.then(e => {
              dispatch(addError(e.message));
            });
          }
        }
      });
  };
};

// Handling the Service Mail Endpoint
export const serviceRequestMail = (serviceType, payload) => {
  return (dispatch) => {
    return apiClient.post(`/organisation/service-mail/${serviceType}`, payload)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem...
          return Promise.reject(data.data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        if (typeof err === 'object' && typeof err.then === 'function') {
          err.then(e => {
            dispatch(addError(e.message));
          });
        }
      });
  };
};

// ------------------------------------
// Reducer
// ------------------------------------
const selectedOrder = getSelectedOrder();
const initialState = {
  searchResult: {},
  selectedOrder: Object.keys(selectedOrder).length > 0
    ? selectedOrder
    : {}

};

export const serviceRequestReducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_SELECTED_ORDER:
    setSelectedOrderConfig(action.payload);
    return { ...state, selectedOrder: action.payload };
  case SEND_SEARCH_REQUEST:
    return { ...state, searchResult: action.payload };
  case RESET_SEARCH_REQUEST:
    return { ...state, searchResult: {} };
  default:
    return state;
  }
};

export default serviceRequestReducer;
